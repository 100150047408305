:root {
  /* 
  COLORS
  */
  /* Card Colors */
  --primary-bg: 239, 45, 86;
  --secondary-bg: 163, 98, 30;
  --third-bg: 84, 69, 127;
  --fourth-bg: 134, 163, 151;
  /* Text and Body Colors */
  --primary: rgb(214, 46, 46);
  --text-color: rgb(41, 39, 39);
  --pale-text-color: #999;
  --body-bg: #f0f5f4;
  --border-color: #e3e3e3;
  --white: #fff;
  /* Logo Colors */
  --logo-color: rgb(105, 112, 122);
  /* 
  ELEMENTS CONTANTS
  */
  --header-min-height: 275px;
  --footer-max-height: 90px;
  --timelinePanelYoffset: -62px;
  --timelinePanelXoffset: 180px;
  /* 
  ANIMATION CONTANTS
  */
}
