.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0 auto;
}

nav.container {
  position: relative;
}

.geschichte main .container {
  padding: 0 24px;
}

.erinnerungen main .container {
  margin: 0 auto;
}

.gaestebuch main .container {
  padding: 0 24px;
  margin: 0 auto;
}

.erinnerungen main .container .post-grid-container {
  display: block;
  columns: 16rem;
  gap: 1rem;
  width: 100%;
  padding: 0 24px;
  margin: 32px 0 0;
}

.post-grid-container .news-posting {
  /* margin: 0 0 1rem; */
  margin: 0 auto 1rem;
  min-height: auto;
  page-break-inside: avoid;
  break-inside: avoid;
}

@media screen and (min-width: 768px) {
  .geschichte main .container,
  .erinnerungen main .container,
  .schulleben main .container,
  .gaestebuch main .container {
    margin: 32px auto 0;
    padding: 0 0 32px 0;
  }
  .gaestebuch main .container {
    padding: 0;
  }
}

@media screen and (min-width: 1366px) {
  .container {
    flex-direction: column;
    max-width: 1228px;
  }
  .home nav.container {
    max-width: 100%;
  }

  .geschichte main .container,
  .gaestebuch main .container {
    max-width: 1080px;
  }
  .erinnerungen main .container {
    max-width: 1228px;
  }
  .erinnerungen main .container .post-grid-container,
  .schulleben main .container .content,
  .gaestebuch main .container .guestbook-container {
    margin: 64px 0 0 0;
  }
  .gaestebuch main .container .guestbook-container {
    margin-top: 0;
  }

  /* .card-row {
    flex-direction: row;
    justify-content: center;
  }
  .card {
    flex: 0 0 320px;
  } */
}
