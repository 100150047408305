body {
  font-family: "Fira Sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--text-color);
  font-size: 16px;
  font-weight: 200;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Zilla Slab";
  font-weight: 600;
}

.title {
  margin: 32px 0;
  white-space: pre-wrap;
}

.title-alt {
  margin: 32px 0;
  white-space: pre-wrap;
  text-align: center;
  padding: 0 8px;
  font-size: 120%;
  line-height: 1.1;
  font-weight: 300;
}

.title-alt span {
  font-size: 80%;
}

.modal-content {
  padding: 18px 32px;
  background: var(--text-color);
  max-height: 80vh;
  overflow: hidden;
  overflow-y: auto;
}

.text-content,
.modal-content {
  padding: 0 24px;
  text-align: center;
}
.text-content {
  text-align: left;
}
.modal-content {
  padding: 18px 32px;
  background: var(--text-color);
  max-height: 80vh;
  overflow: hidden;
  overflow-y: auto;
}

.modal-content > * {
  color: var(--body-bg) !important;
}

.modal-content img {
  margin: 12px auto 0;
  max-height: 75vh;
}

.modal-content .text-content-modal {
  text-align: left;
}

.main-heading h1,
.main-heading h2,
.main-heading h3,
.main-heading h4 {
  text-transform: capitalize;
}

.main-heading h1 {
  font-size: 1.7em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

nav a {
  font-weight: 400;
}

footer {
  font-size: 90%;
  color: var(--pale-text-color);
}

footer .copyright::before {
  font-size: 18px;
}

footer a,
footer a:active,
footer a:visited {
  color: var(--pale-text-color);
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .title {
    font-size: 260%;
  }
  .title-alt {
    font-size: 185%;
    white-space: pre-wrap;
    max-width: 640px;
    margin: 0 auto 32px;
  }
  .title-alt span {
    font-size: 70%;
  }
  .text-content,
  .modal-content {
    font-size: 120%;
    white-space: initial;
    max-width: 640px;
    margin: 64px auto 0;
  }
  .modal-content {
    max-width: 86%;
  }
}

@media screen and (min-width: 1024px) and (orientation: portrait) {
  .title {
    font-size: 300%;
  }
  .title-alt,
  .text-content {
    max-width: 760px;
  }
  .modal-content {
    max-width: 80%;
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .title {
    font-size: 180%;
    white-space: initial;
  }
  .title-alt,
  .text-content {
    max-width: 760px;
  }
}

@media screen and (min-width: 1280px) {
  .title-alt {
    max-width: 800px;
    font-size: 178%;
    margin: 0 auto 64px;
  }
  .title-alt span {
    font-size: 70%;
  }
  .text-content {
    max-width: 860px;
    font-size: 112%;
  }
  .modal-content {
    max-width: 70%;
  }
}

@media screen and (min-width: 1366px) {
  .title-alt,
  .text-content {
    max-width: 860px;
  }
}

@media screen and (min-width: 1440px) {
  .title-alt,
  .text-content {
    max-width: 900px;
  }
}

@media screen and (min-width: 1920px) {
  .title {
    font-size: 220%;
    white-space: pre-wrap;
  }
  .title-alt,
  .text-content {
    max-width: 900px;
  }
}
