.gallery-modal {
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.85);
  z-index: 9990;
  /* pointer-events: none; */
}

.modal-dismiss {
  position: absolute;
  top: 24px;
  right: 48px;
  cursor: pointer;
  z-index: 9991;
}

.gallery-controls {
  position: absolute;
  top: calc(50% - 18px);
  left: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 48px;
}

.gallery-controls .left,
.gallery-controls .right {
  cursor: pointer;
}

.modal-container,
.modal-body {
  position: relative;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-body .loader-holder {
  position: absolute;
  width: 40px;
  height: 40px;
  z-index: 999;
}

.gallery-modal.show-modal {
  display: flex;
}

.img-container img {
  border: 12px solid white;
  box-shadow: 0 0 22px -6px rgba(0, 0, 0, 0.5);
  opacity: 0;
  transition: opacity 0.4s ease-in-out;
}

.gallery-modal .img-container.portrait {
  height: 100%;
  max-height: 60vh;
  width: auto;
}

.img-container.portrait img {
  height: inherit;
}

.gallery-modal .img-container.landscape {
  width: 100%;
  max-width: 60vw;
  height: auto;
}

.img-container.landscape img {
  width: inherit;
}

.img-container img.show-img {
  opacity: 1;
}

.vid-container-wrapper {
  max-width: 100%;
  width: 80vw;
  height: calc((80vw / 16) * 9);
  left: 0;
  right: 0;
  margin: auto;
}

.vid-container {
  position: relative;
  height: inherit;
}

.vid-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9994;
}

.gallery-filter {
  margin: 16px 0;
}
.gallery-filter nav {
  display: flex;
  flex-direction: column;
}

.gallery-filter div {
  padding: 0 12px;
  cursor: pointer;
  font-weight: 700;
  text-align: center;
  margin: 6px 0;
}

.gallery-filter div.active {
  color: var(--primary);
}

.container .gallery-filter + div {
  width: 100%;
}

.gallery {
  display: block;
  columns: 16rem;
  grid-gap: 1rem;
  gap: 1rem;
  width: 100%;
  padding: 24px;
  visibility: hidden;
}

.gallery.gallery-visible {
  visibility: visible;
}

.gallery .gallery-item.show-figure {
  transform: scale3d(1, 1, 1);
}

.gallery .gallery-item {
  margin: 0 0 1rem 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transform: scale3d(0, 0, 0);
  transition: transform 0.3s ease-out;
}

.gallery-item img {
  width: 100%;
  object-fit: cover;
  object-position: center center;
  border-style: solid;
  border-width: 10px;
  border-color: var(--white);
  box-shadow: 0 0 22px -6px rgba(0, 0, 0, 0.3);
  /* transform: scale3d(1, 1, 1);
  transition: transform 0.3s ease-out; */
}

.gallery .gallery-item:hover {
  transform: scale3d(0.945, 0.945, 0.945);
  backface-visibility: hidden;
}

@media screen and (min-width: 768px) {
  .gallery-filter nav {
    flex-direction: row;
  }

  .gallery-filter div {
    text-align: left;
    margin: 0;
  }

  .gallery-modal .img-container.portrait {
    max-height: 80vh;
  }
}

@media screen and (min-width: 1024px) {
  .gallery-modal .img-container.portrait {
    max-height: 85vh;
  }
}

@media screen and (min-width: 1280px) {
  .gallery-modal .img-container.portrait {
    max-height: 100vh;
  }
}
