.bottom2top {
  animation: bottom2top var(--animation-duration) var(--animation-timing-fn);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: calc(var(--animation-duration) * 0.1);
  transform-origin: center top;
  transform: rotateX(78deg) translateZ(-250px) translateY(50px);
  opacity: 0;
}

@keyframes bottom2top {
  from {
    transform: rotateX(78deg) translateZ(-250px) translateY(50px);
    opacity: 0;
  }
  to {
    transform: rotateX(0deg) translateZ(0) translateY(0);
    opacity: 1;
  }
}

.right2left {
  animation: right2left var(--animation-duration) var(--animation-timing-fn);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: calc(var(--animation-duration) * 0.2);
  transform-origin: right center;
  transform: rotateY(78deg) translateZ(-250px) translateX(50px);
  opacity: 0;
}

@keyframes right2left {
  from {
    transform: rotateY(78deg) translateZ(-250px) translateX(50px);
    opacity: 0;
  }
  to {
    transform: rotateY(0deg) translateZ(0) translateY(0) scale(1);
    opacity: 1;
  }
}

.left2right {
  animation: left2right var(--animation-duration) var(--animation-timing-fn);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: calc(var(--animation-duration) * 0.3);
  transform-origin: left center;
  transform: rotateY(-78deg) translateZ(-250px) translateX(-50px);
  opacity: 0;
}

@keyframes left2right {
  from {
    transform: rotateY(-78deg) translateZ(-250px) translateX(-50px);
    opacity: 0;
  }
  to {
    transform: rotateY(-0deg) translateZ(0) translateY(0) scale(1);
    opacity: 1;
  }
}

.top2bottom {
  animation: top2bottom var(--animation-duration) var(--animation-timing-fn);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: calc(var(--animation-duration) * 0.4);
  transform-origin: center bottom;
  transform: rotateX(-78deg) translateY(-50px);
  opacity: 0;
}

@keyframes top2bottom {
  from {
    transform: rotateX(-78deg) translateY(-50px);
    opacity: 0;
  }
  to {
    transform: rotateX(0deg) translateZ(0) translateY(0) scale(1);
    opacity: 1;
  }
}

.reveal-1 {
  animation: reveal var(--animation-duration) var(--animation-timing-fn);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: calc(var(--animation-duration) * 0.2);
  transform-origin: center bottom;
  transform: translateY(32px);
  opacity: 0;
}
.reveal-2 {
  animation: reveal var(--animation-duration) var(--animation-timing-fn);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: calc(var(--animation-duration) * 0.6);
  transform-origin: center bottom;
  transform: translateY(46px);
  opacity: 0;
}

.reveal-3 {
  animation: reveal var(--animation-duration) var(--animation-timing-fn);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: calc(var(--animation-duration) * 0.7);
  transform-origin: center bottom;
  transform: translateY(22px);
  opacity: 0;
}

.reveal-4 {
  animation: reveal var(--animation-duration) var(--animation-timing-fn);
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  animation-delay: calc(var(--animation-duration) * 0.9);
  transform-origin: center bottom;
  transform: translateY(38px);
  opacity: 0;
}

@keyframes reveal {
  from {
    transform: translateY(18px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes rubberBand {
  from {
    transform: scale3d(0, 0, 0);
  }

  30% {
    transform: scale3d(0.25, 0.75, 0);
  }

  40% {
    transform: scale3d(0.75, 0.25, 0);
  }

  50% {
    transform: scale3d(1.15, 0.85, 1);
  }

  65% {
    transform: scale3d(0.95, 1.05, 1);
  }

  75% {
    transform: scale3d(1.05, 0.95, 1);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
