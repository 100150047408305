.container .content {
  display: flex;
  flex-direction: column;
  padding: 0 24px;
  text-align: center;
}

.content .left-content {
  padding: 0 16px 0 0;
}
.content .right-content {
  padding: 0 0 0 16px;
}

.content .right-content img {
  width: 100%;
  height: auto;
  border: 8px solid var(--white);
  box-shadow: inset 0 0 0 8px var(--white), 0 0 14px -4px rgba(0, 0, 0, 0.2);
}

.content .left-content .title {
  font-size: 120%;
  color: var(--primary);
}

@media screen and (min-width: 1024px) {
  .container .content {
    flex-direction: row;
    text-align: left;
  }
  .container .content div {
    flex: 0 0 50%;
  }
}
