.mobile-text {
  padding: 0 16px;
  text-align: center;
  max-width: 100%;
}

.mobile-text ul li {
  padding-left: 12px;
  line-height: 1.8;
}

.mobile-text ul li::marker {
  content: "▸";
  color: var(--primary);
}

.flex-ender {
  justify-content: flex-end;
}

.primary-bg {
  background-color: rgba(var(--primary-bg), 0.75);
}

.secondary-bg {
  background-color: rgba(var(--secondary-bg), 0.75);
}

.third-bg {
  background-color: rgba(var(--third-bg), 0.75);
}

.fourth-bg {
  background-color: rgba(var(--fourth-bg), 0.75);
}

.right-bottom-pos {
  bottom: -50%;
  right: -25%;
}

.left-bottom-pos {
  bottom: -50%;
  left: -25%;
}

.left-top-pos {
  top: -50%;
  left: -25%;
}

.right-top-pos {
  top: -50%;
  right: -25%;
}

.phat-border {
  border-style: solid;
  border-width: 10px;
  border-color: transparent;
}

.white-border {
  border-color: white;
}

.shadow {
  box-shadow: 0 0 22px -6px rgba(0, 0, 0, 0.3);
}

.lite-gray-bg {
  background-color: var(--body-bg);
}

.left-test {
  /* background-color: tomato !important; */
  opacity: 1 !important;
  transform: translate3d(0, var(--timelinePanelYoffset), 0) !important;
}

.right-test {
  /* background-color: lawngreen !important; */
  opacity: 1 !important;
  transform: translate3d(0, var(--timelinePanelYoffset), 0) !important;
}

.grecaptcha-badge {
  visibility: hidden;
}

@media screen and (min-width: 1024px) {
  .mobile-text {
    padding: 0 24px;
    max-width: 800px;
  }
}

@media screen and (min-width: 1280px) {
  .mobile-text {
    text-align: left;
    max-width: 1080px;
  }
}

@media screen and (min-width: 1366px) {
  .mobile-text {
    max-width: 100%;
  }
}
