html {
  box-sizing: border-box;
  overflow-x: hidden;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  margin: 0;
}

body.is-modal {
  overflow-y: hidden !important;
}

a,
a:active,
a:visited,
a:focus {
  color: inherit;
}

#root > div > div {
  min-height: 100vh;
}

.home {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  min-height: 100vh;
}

.geschichte,
.erinnerungen,
.schulleben,
.gaestebuch {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  /* align-content: center;
  align-items: center; */
  min-height: 101vh !important;
  overflow-x: hidden;
}

.gaestebuch {
  /* min-height: initial !important; */
}

header {
  position: fixed;
  padding: 16px 0 0;
  padding: 16px 0;
  text-align: center;
  /* min-height: var(--header-min-height); */
  min-height: initial;
  width: 100vw;
  background-color: var(--body-bg);
  z-index: 1111;
}

header nav {
  width: 100%;
  background: var(--text-color);
  color: var(--body-bg);
  max-height: 0;
  overflow-x: hidden;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  position: absolute;
  top: calc(var(--header-min-height) + 16px);
  top: 112px;
  left: 0;
  z-index: 9999;
}

header nav div {
  display: block;
}

header nav.is-active {
  max-height: 75vh;
}

header nav a {
  display: block;
  padding: 24px 0;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 108%;
}

header nav a.active,
header nav a:active.active,
header nav a:visited.active,
header nav a:focus.active {
  color: var(--primary) !important;
}

header nav a.active::before {
  content: "";
  display: block;
  width: 100%;
  height: 0;
  background: var(--primary);
}

header nav div:first-child {
  margin: 24px 0 0 0;
}

header nav div:last-child {
  margin: 0 0 24px 0;
}

.home header nav {
  display: none;
}

header div {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

main {
  margin: 112px 0 32px 0;
  max-width: 100vw;
}

.geschichte main,
.erinnerungen main,
.schulleben main,
.gaestebuch main {
  /* justify-self: flex-start; */
  /* margin: calc(var(--header-min-height) + 32px) 0 32px 0; */
  margin: 112px 0 32px 0;
}

.geschichte main,
.erinnerungen main,
.schulleben main {
  min-height: 110vh;
}

footer {
  height: 100px;
  align-self: stretch;
  display: none;
  flex-direction: column-reverse;
  background-color: var(--text-color);
  justify-content: center;
  align-items: center;
  padding: 16px 0;
  min-height: 100px;
  margin-top: 24px;
}

footer.show-footer {
  display: flex;
}

footer > div:first-child {
  margin: 0;
}

footer > div:last-child {
  margin: 0 0 8px 0;
}

footer > div:last-child a {
  margin: 0 8px;
}

footer .copyright {
  position: relative;
  padding: 0 0 0 18px;
}

footer .copyright::before {
  content: "©";
  position: absolute;
  top: -4px;
  left: 0;
}

@media screen and (min-width: 768px) {
  .gaestebuch {
    min-height: 100hv !important;
  }
  header {
    padding: 0;
    min-height: 0;
    height: 0;
  }
  .geschichte header,
  .erinnerungen header,
  .schulleben header,
  .gaestebuch header {
    padding: 32px 0 0 0;
    height: auto;
    border-bottom: 1px solid var(--border-color);
    box-shadow: 0 0 22px -6px rgba(0, 0, 0, 0.15);
    transition: padding 0.3s ease;
  }
  .home header {
    position: relative;
  }
  .geschichte header.shrink-header,
  .erinnerungen header.shrink-header,
  .schulleben header.shrink-header,
  .gaestebuch header.shrink-header {
    padding: 0;
  }

  header > div {
    justify-content: center;
  }
  header .hamburger {
    display: none;
  }
  header nav {
    position: relative;
    min-height: 67px;
    overflow: initial;
    top: 0;
    background: var(--body-bg);
    margin: 16px 0;
  }
  header nav div {
    display: inline-block;
  }
  header nav div > a {
    display: inline-block;
    padding: 24px 24px;
    text-decoration: none;
    color: var(--text-color) !important;
  }
  header nav a.active::before {
    height: 2px;
  }
  header nav div:first-child {
    margin: 0;
  }
  header nav div:last-child {
    margin: 0;
  }
  .geschichte main,
  .erinnerungen main,
  .schulleben main,
  .gaestebuch main {
    /* justify-self: flex-start; */
    margin: calc(var(--header-min-height) + 32px) 0 32px 0;
    /* margin: 112px 0 32px 0; */
  }
  .gaestebuch main {
    margin: calc(var(--header-min-height) + 32px) 0 0 0;
  }
  .home main {
    margin: 128px 0 0 0;
  }

  footer {
    flex-direction: row;
  }
  footer > div:last-child {
    margin: 0;
  }
  footer > div:last-child a {
    margin: 0;
  }
  footer div:first-child {
    margin: 0 12px 0 0;
  }
  footer > div:last-child a {
    margin: 0 12px 0 0;
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  header {
    padding: 32px 0 0;
  }
}

@media screen and (min-width: 1024px) and (orientation: portrait) {
  header {
    padding: 64px 0 0;
  }
}

@media screen and (min-width: 1280px) and (max-height: 800px) {
  header {
    padding: 32px 0 0;
  }
}

@media screen and (min-width: 1280px) and (min-height: 800px) {
  header {
    padding: 64px 0 0;
  }
  .home header {
    padding: 32px;
  }
  /* .home > header {
    display: none;
  } */
}
