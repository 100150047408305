.sidebar-layout {
  /* display: flex;
  flex-direction: column; */
  margin-bottom: 64px;
}

.form-holder {
  display: none;
  position: fixed;
  margin: 0 0 48px;
  top: 0;
  left: 0;
  /* background-color: var(--body-bg); */
  background-color: rgba(255, 255, 255, 1);
  width: 100%;
  height: 100vh;
}

.form-holder.show-form {
  display: flex !important;
  align-items: center;
  z-index: 4444;
}

.guestbook-form-container {
  width: 100%;
  padding: 0 24px;
  margin: 0 auto;
}

.guestbook-form-container form {
  width: 100%;
}

.guestbook-form-container form label {
  font-size: 96%;
}

.guestbook-form-container form input {
  min-height: 48px;
  width: 100%;
  margin: 0;
  border-style: solid;
  margin: 0 0 16px;
  padding: 0 16px;
  border-color: var(--border-color);
}

.guestbook-form-container form textarea {
  resize: none;
  width: 100%;
  padding: 0;
  border-style: solid;
  padding: 16px;
  margin: 0 0 16px;
  border-color: var(--border-color);
}

form .button-control {
  display: flex;
  justify-content: space-between;
}

.gaestebuch .main-heading {
  position: relative;
  width: 90vw;
}
.gaestebuch .main-heading span {
  position: absolute;
  right: 0;
  top: calc(50% - 8px);
  cursor: pointer;
}

.gaestebuch .main-heading span svg {
  fill: var(--text-color);
}

.guestbook-container {
  position: relative;
  flex: 1;
  width: 90vw;
  height: inherit;
  /* overflow: hidden; */
}

.guestbook-entry {
  position: absolute;
  width: 100%;
  opacity: 0;
  left: 100%;
  top: 32px;
  /* margin: 48px auto 48px; */
  background-color: var(--white);
  padding: 32px 48px;
  transition: left 0.4s ease-in-out, opacity 0.3s linear;
}

.guestbook-entry.animate-in {
  left: 0 !important;
  opacity: 1 !important;
}

.guestbook-entry.animate-out {
  left: -100% !important;
  opacity: 0 !important;
}

/* .guestbook-entry:first-child {
  padding: 0;
  margin: 0 0 16px 0;
  background-color: transparent;
  text-align: right;
  color: var(--text-color);
} */

.guestbook-entry img {
  display: block;
}

.guestbook-entry .op {
  display: inline-block;
}

.guestbook-entry .message {
  margin: 0;
}

.guestbook-entry .timer-container {
  position: relative;
  height: 6px;
  width: 100%;
}

.timer-container .timer {
  position: absolute;
  width: 0;
  height: 2px;
  background-color: var(--primary);
  left: -48px;
  top: 36px;
  transition-property: width;
  transform-origin: left center;
  transition-timing-function: linear;
}

.timer.run {
  width: calc(100% + 96px);
}

@media screen and (min-width: 768px) {
  .sidebar-layout {
    margin-bottom: 64px;
  }
  .guestbook-form-container {
    max-width: 680px;
  }
}

@media screen and (min-width: 1024px) {
  .gaestebuch .main-heading {
    width: 70vw;
  }
  .guestbook-container {
    width: 70vw;
  }
}

@media screen and (min-width: 1280px) {
  .sidebar-layout {
    margin-bottom: 86px;
    max-width: 1080px;
  }
  .gaestebuch .main-heading,
  .guestbook-container {
    width: 1080px;
    padding: 0 24px;
  }
  .guestbook-container {
    width: 1036px;
  }
}

@media screen and (min-width: 1366px) {
  .sidebar-layout {
    margin-bottom: 86px;
    max-width: 1080px;
  }
  .gaestebuch .main-heading,
  .guestbook-container {
    width: 1080px;
    padding: 0 24px;
  }
  .guestbook-container {
    width: 1036px;
  }
}

@media screen and (min-width: 1536px) {
  .gaestebuch .main-heading {
    width: 1080px;
  }
  .guestbook-container {
    width: 1036px;
    margin: 0 !important;
  }
  .guestbook-entry {
    top: 64px;
  }
}

@media screen and (min-width: 1920px) {
  .gaestebuch .main-heading,
  .guestbook-container {
    width: 1080px;
    padding: 0 24px;
  }
  .guestbook-container {
    margin: 0 !important;
    width: 1036px;
  }
}
