button {
  cursor: pointer;
}

.btn {
  font-family: inherit, sans-serif;
  font-weight: 500;
  font-size: 106%;
  border: none;
  padding: 0.8rem 1.4rem;
  /* min-width: 230px;
  min-height: 60px; */
  /* border-radius: 30px; */
  text-align: center;
  text-transform: capitalize;
  margin: 0;
}

.btn:active,
.btn:visited,
.btn:focus {
  outline: none;
}

a.btn {
  text-decoration: none;
  display: inline-block;
  vertical-align: middle;
  line-height: 60px;
}

.btn-default {
  background-color: var(--primary);
  color: var(--white);
  transition: all 0.2s linear;
}

.btn-ghost {
  background-color: transparent;
  box-shadow: inset 0 0 0 4px var(--primary);
  color: var(--white);
  transition: all 0.2s linear;
}

.btn-default:hover {
  background-color: #f04141;
  color: var(--white);
}

.btn-ghost:hover {
  box-shadow: inset 0 0 0 4px var(--white);
  color: var(--primary);
}

.btn-default.primary-hover:hover {
  background-color: var(--primary);
  color: var(--white);
}

.btn-ghost.primary-hover:hover {
  box-shadow: inset 0 0 0 4px var(--primary);
  color: var(--primary);
}

.btn-default.no-hover:hover {
  background-color: var(--primary);
  color: var(--white);
}

.btn-ghost.no-hover:hover {
  box-shadow: inset 0 0 0 4px var(--black);
  color: var(--black);
}

.btn-play {
  min-width: 76px !important;
  min-height: 76px !important;
  border-radius: 38px;
  position: absolute;
  top: calc(50% - 38px);
  left: calc(50% - 38px);
  z-index: 1047;
}

@media screen and (min-width: 768px) {
  .btn {
    flex: 0;
    align-self: center;
  }
}
