.card-row {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
  perspective: 940px;
  perspective-origin: bottom;
  --animation-duration: 0.85s;
  --animation-timing-fn: cubic-bezier(0.9, 0.1, 0.695, 0.045);
}

.card-row:first-child {
  perspective-origin: bottom;
}

.card-row:last-child {
  perspective-origin: top;
}

/* .card-row:not(.card-row:last-child) {
  margin: 0 0 16px;
} */

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 0 0 300px;
  height: 292px;
  max-height: 292px;
  background: transparent;
  margin: 8px;
  border-top-width: 0 !important;
  cursor: pointer;
  overflow: hidden;
  transform-style: preserve-3d;
  backface-visibility: visible;
}

nav.container .bg-img-holder {
  opacity: 0;
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../img/unsere-schule-eulenstrasse.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: -1;
  transition: opacity 2s 1s ease;
}

nav.container .bg-img-holder.show-bg-img {
  opacity: 1;
}

/* .card .bg-img-holder {
  background: transparent;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: url(../img/unsere-schule-eulenstrasse.jpg);
  background-repeat: no-repeat;
  background-position: center center;
  background-attachment: fixed;
  background-size: cover;
  z-index: 10;
} */

.card h2 {
  position: relative;
  z-index: 30;
  color: var(--text-color);
  font-weight: 300;
  text-align: center;
  /* padding: 0 32px 0 0; */
  margin: 0;
  padding: 5px 0;
  font-size: 140%;
  transform: scale(1);
  transform-origin: right center;
  transition: all 0.5s 0.1s cubic-bezier(0.47, 1.64, 0.41, 0.8);
  -webkit-font-smoothing: subpixel-antialiased;
  backface-visibility: hidden;
  transform-style: flat;
  pointer-events: none;
  user-select: none;
  background-color: var(--white);
}

.bg-img-holder::before {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0);
}

.bg-color {
  position: absolute;
  animation: rubberBand 1.4s linear;
  animation-fill-mode: forwards;
  animation-iteration-count: 1;
  width: 292px;
  height: 292px;
  border-radius: 50%;
  z-index: 20;
  pointer-events: none;
  transform: scale(0);
  transform-origin: right bottom;
  transition: all 0.5s cubic-bezier(0.47, 1.64, 0.41, 0.8);
}

.bg-color svg {
  max-width: 50%;
  /* transform: translate(56px, 44px); */
}

svg .school-icon {
  fill: var(--white);
}

.primary-bg svg {
  transform: translate(132px, 52px);
}
.third-bg svg {
  transform: translate(132px, 52px);
}
.secondary-bg svg {
  transform: translate(96px, 48px);
}
.fourth-bg svg {
  transform: translate(96px, 64px);
}

.card:hover > .bg-color {
  transform: scale3d(1.15, 0.85, 1);
  background: black;
  bottom: -45%;
  right: -20%;
}

.card:hover > .primary-bg {
  background-color: rgba(var(--primary-bg), 1);
}

.card:hover > .secondary-bg {
  background-color: rgba(var(--secondary-bg), 1);
}

.card:hover > .third-bg {
  background-color: rgba(var(--third-bg), 1);
}

.card:hover > .fourth-bg {
  background-color: rgba(var(--fourth-bg), 1);
}

@media screen and (min-width: 768px) {
  .card-row {
    flex-direction: row;
    justify-content: center;
  }
  .card {
    flex: 0 0 280px;
    height: 256px;
    max-height: 256px;
  }
  .bg-color {
    width: 256px;
    height: 256px;
  }
  .right-bottom-pos + h2,
  .right-top-pos + h2 {
    text-align: right;
  }
  .left-top-pos + h2,
  .left-bottom-pos + h2 {
    text-align: left;
  }
  .primary-bg svg {
    transform: translate(106px, 42px);
  }
  .third-bg svg {
    transform: translate(116px, 42px);
  }
  .secondary-bg svg {
    transform: translate(86px, 38px);
  }
  .fourth-bg svg {
    transform: translate(80px, 46px);
  }
}

@media screen and (min-width: 1024px) {
  .card {
    height: 256px;
    max-height: 256px;
  }
  .bg-color {
    width: 256px;
    height: 256px;
  }
  .card h2 {
    font-size: 140%;
  }
  .primary-bg svg {
    transform: translate(112px, 52px);
  }
  .third-bg svg {
    transform: translate(132px, 52px);
  }
  .secondary-bg svg {
    transform: translate(96px, 48px);
  }
  .fourth-bg svg {
    transform: translate(96px, 64px);
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .card {
    height: 256px;
    max-height: 256px;
  }
  .bg-color {
    width: 256px;
    height: 256px;
  }
  .card h2 {
    font-size: 140%;
  }
  .primary-bg svg {
    transform: translate(118px, 46px);
  }
  .third-bg svg {
    transform: translate(118px, 40px);
  }
  .secondary-bg svg {
    transform: translate(72px, 38px);
  }
  .fourth-bg svg {
    transform: translate(72px, 44px);
  }
}

@media screen and (min-width: 1024px) and (orientation: portrait) {
  .card {
    flex: 0 0 400px;
    height: 420px;
    max-height: 420px;
  }
  .bg-color {
    width: 380px;
    height: 380px;
  }
  .primary-bg svg {
    transform: translate(164px, 64px);
  }
  .third-bg svg {
    transform: translate(164px, 64px);
  }
  .secondary-bg svg {
    transform: translate(148px, 64px);
  }
  .fourth-bg svg {
    transform: translate(148px, 68px);
  }
}

@media screen and (min-width: 1280px) and (max-height: 800px) {
  .card {
    flex: 0 0 400px;
    height: 240px;
    max-height: 240px;
  }
  .bg-color {
    width: 240px;
    height: 240px;
  }
  .primary-bg svg {
    transform: translate(124px, 38px);
  }
  .third-bg svg {
    transform: translate(124px, 38px);
  }
  .secondary-bg svg {
    transform: translate(54px, 34px);
  }
  .fourth-bg svg {
    transform: translate(48px, 42px);
  }
}

@media screen and (min-width: 1280px) and (min-height: 800px) {
  .card {
    flex: 0 0 400px;
    height: 240px;
    max-height: 240px;
  }
  .bg-color {
    width: 240px;
    height: 240px;
  }
}

@media screen and (min-width: 1366px) and (min-height: 1024px) {
  .card {
    flex: 0 0 400px;
    height: 260px;
    max-height: 260px;
  }
  .bg-color {
    width: 260px;
    height: 260px;
  }
}

@media screen and (min-width: 1440px) {
  .card {
    flex: 0 0 420px;
    height: 296px;
    max-height: 296px;
  }
  .bg-color {
    width: 296px;
    height: 296px;
  }
  .primary-bg svg {
    transform: translate(136px, 56px);
  }
  .third-bg svg {
    transform: translate(142px, 54px);
  }
  .secondary-bg svg {
    transform: translate(88px, 48px);
  }
  .fourth-bg svg {
    transform: translate(82px, 56px);
  }
}

@media screen and (min-width: 1536px) {
  /* .card {
    flex: 0 0 420px;
    height: 240px;
    max-height: 240px;
  }
  .bg-color {
    width: 240px;
    height: 240px;
  } */
}

@media screen and (min-width: 1920px) {
  /* .card {
    flex: 0 0 400px;
    max-height: 240px;
    height: 240px;
  }
  .bg-color {
    width: 240px;
    height: 240px;
  } */
}
