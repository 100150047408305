.logo-container {
  max-width: 80px;
  width: 80px;
  cursor: pointer;
  padding: 0;
  border-width: 0 !important;
  border-radius: 50%;
  margin: 0 0 0 12px;
  opacity: 0;
  transition: opacity 0.3s linear;
  transition-delay: 500s;
}
/* .home .logo-container {
  margin: 0 auto;
} */
.home .logo-container,
.geschichte .logo-container,
.erinnerungen .logo-container,
.schulleben .logo-container,
.gaestebuch .logo-container {
  transition-delay: 0s !important;
  opacity: 1;
}

.logo-container.show-logo {
  opacity: 1;
}

.logo {
  fill: var(--logo-color);
}

main nav .logo-container.home-logo {
  display: none !important;
  transition: all 0.9s 1.4s ease;
  opacity: 0;
}

.geschichte .shrink-header .logo-container,
.erinnerungen .shrink-header .logo-container,
.schulleben .shrink-header .logo-container,
.gaestebuch .shrink-header .logo-container {
  max-width: 0 !important;
  border-color: transparent !important;
}

@media screen and (min-width: 768px) {
  .logo-container {
    position: absolute;
    padding: 0;
    max-width: 180px;
    width: 180px;
    margin: 0;
    top: calc(50% - 90px);
    left: calc(50% - 90px);
    z-index: 9998;
    background-color: var(--body-bg);
    border-radius: 50%;
    border-width: 4px !important;
  }
  /* main nav .logo-container.home-logo {
    display: block !important;
    opacity: 0;
  } */
  main nav .logo-container.show-logo {
    opacity: 1;
  }
  .home .logo-container,
  .geschichte .logo-container,
  .erinnerungen .logo-container,
  .schulleben .logo-container,
  .gaestebuch .logo-container {
    display: block;
    position: static;
    max-width: 112px;
    width: 112px;
    transition: all 0.3s linear;
  }
}

@media screen and (min-width: 1024px) and (orientation: portrait) {
  .logo-container {
    top: calc(50% - 90px);
    left: calc(50% - 100px);
    max-width: 200px;
    width: 200px;
  }
}

@media screen and (min-width: 1024px) and (orientation: landscape) {
  .logo-container {
    top: calc(50% - 90px);
  }
}

@media screen and (min-width: 1280px) and (max-height: 799px) {
  .logo-container {
    max-width: 180px;
    width: 180px;
    top: calc(50% - 90px);
    left: calc(50% - 90px);
  }
}

@media screen and (min-width: 1280px) and (min-height: 800px) {
  .logo-container {
    max-width: 180px;
    width: 180px;
    top: calc(50% - 90px);
    left: calc(50% - 90px);
  }
}

@media screen and (min-width: 1366px) and (max-height: 1023px) {
  .logo-container {
    max-width: 180px;
    width: 180px;
    top: calc(50% - 90px);
    left: calc(50% - 90px);
  }
}

@media screen and (min-width: 1366px) and (min-height: 1024px) {
  .logo-container {
    max-width: 200px;
    width: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
  }
}

@media screen and (min-width: 1440px) {
  .logo-container {
    max-width: 200px;
    width: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
  }
}

@media screen and (min-width: 1920px) {
  .logo-container {
    max-width: 200px;
    width: 200px;
    top: calc(50% - 100px);
    left: calc(50% - 100px);
  }
}
