.nav-link-container {
  position: relative;
}

.sub-menu-container {
  display: flex;
  flex-direction: column;
  background-color: var(--text-color);
  overflow: hidden;
}

.sub-menu-container a {
  font-weight: 300;
  padding: 6px 0;
}

@media screen and (min-width: 768px) {
  .sub-menu-container {
    background-color: var(--white);
    align-items: flex-start;
    position: absolute;
    left: 20px;
    top: 64px;
    width: auto;
    font-size: 96%;
    max-height: 0;
    transition: all 0.3s 0.2s linear;
    border: 0 solid transparent;
    box-shadow: none;
  }
  .sub-menu-container a {
    padding: 6px 18px;
    white-space: nowrap;
    display: block;
    width: 100%;
    text-align: left;
    color: var(--text-color);
    border-bottom: 1px solid var(--border-color);
    text-transform: none;
  }
  .sub-menu-container a:first-child {
    padding: 12px 18px 6px;
  }
  .sub-menu-container a:last-child {
    padding: 6px 18px 12px;
    border-bottom: 0 solid transparent;
  }
  .nav-link-container a:hover + .sub-menu-container,
  .sub-menu-container:hover {
    max-height: 230px;
    transition-delay: 0s;
    box-shadow: 0 0 9px -3px rgba(0, 0, 0, 0.2);
    border: 1px solid var(--border-color);
  }
  .sub-menu-container a:hover {
    color: var(--primary) !important;
    background-color: var(--body-bg);
  }
}
