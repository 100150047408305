.card-container {
  position: relative;
  background-color: var(--white);
  text-align: center;
  padding: 72px 12px 64px;
  /* box-shadow: 0 0 14px -4px rgba(0, 0, 0, 0.2); */
  margin: 32px 0 96px;
  /* align-self: center; */
  /* max-width: var(--mobile-max-width); */
}

.card-container.news-posting {
  margin: 0 0 1rem 0;
  padding: 0;
  width: 100%;
  cursor: pointer;
  -moz-backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  text-align: left;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  transform: scale3d(0, 0, 0);
  transition: transform 0.4s ease-out;

  -webkit-column-break-inside: avoid; /* Chrome, Safari */
  page-break-inside: avoid; /* Theoretically FF 20+ */
  break-inside: avoid-column; /* IE 11 */
  display: table; /* Actually FF 20+ */
}

.news-posting.show-post {
  transform: scale3d(1, 1, 1);
}

.news-posting:hover {
  transform: scale3d(0.945, 0.945, 0.945);
  backface-visibility: hidden;
}

.card-container.news-posting img {
  position: static;
  width: 100%;
  break-before: avoid-column;
  break-after: avoid-column;
}

.news-posting .holder {
  padding: 16px 32px;
  /* height: 100%; */
  /* align-self: stretch; */
  /* flex: 1; */
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  box-shadow: 0 0 14px -4px rgba(0, 0, 0, 0.2);
}

.holder .more-link {
  display: none;
}

.news-posting .holder h3 {
  align-self: flex-start;
  width: 100%;
  color: var(--body-color);
  transition: color 0.15s linear;
}

.news-posting:hover .holder h3 {
  color: var(--primary);
  backface-visibility: hidden;
}

.news-posting .holder p {
  margin: 4px 0;
  -webkit-font-smoothing: subpixel-antialiased;
}

.news-posting .date {
  font-weight: 300;
  font-style: italic;
  color: var(--secondary-pale);
  font-size: 82%;
}

.news-posting .author {
  align-self: flex-start;
  flex: 1 1 0px;
  display: flex;
  align-items: flex-end;
  margin: 16px 0 0 0;
}

.news-posting .author img {
  width: 48px;
  height: 48px;
  border-radius: 24px;
}

.news-posting .author span {
  margin: 0 0 12px 16px;
  font-weight: 700;
  font-size: 88%;
}

.card-container h3 {
  text-transform: none;
  font-weight: 700;
  font-size: 122%;
}

.card-container p {
  max-width: 92%;
  margin: 0 auto;
}
