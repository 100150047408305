.timeline {
  margin: 64px 0;
  padding: 0 12px 0 12px;
  list-style-type: none;
  position: relative;
  display: block;
  /* border: 1px solid var(--pale-text-color); */
}

.timeline::before {
  content: "";
  display: none;
  height: 100%;
  border-left: 4px solid var(--border-color);
  min-width: 4px;
  position: absolute;
  margin: 0;
  top: 0;
  left: 0;
  transition: min-width 0.3s ease;
}

.loader-visible + .timeline::before {
  min-width: 0;
  border-left: 1px solid transparent;
}

.timeline li {
  margin: 0 0 32px 0;
}

.timeline-badge {
  display: none;
  font-size: 90%;
  font-weight: 400;
  color: var(--pale-text-color);
}

.timeline-heading h2 {
  margin: 16px 0 16px 0;
  font-size: 122%;
  text-transform: capitalize;
  color: var(--text-color);
  font-weight: 700;
}

.timeline-heading p {
  font-weight: 300;
  font-style: italic;
  color: var(--secondary-pale);
  font-size: 82%;
  margin: 16px 0;
}

.timeline-body img {
  margin: 0 0 16px;
  display: block;
  width: 100%;
}

.timeline .timeline-panel {
  opacity: 0;
  padding: 16px 32px;
  border: 0 solid var(--border-color);
  box-shadow: inset 0 0 0 8px var(--white), 0 0 14px -4px rgba(0, 0, 0, 0.2);
  background: var(--white);
  position: relative;
  transition: all 0.6s ease-out;
}

.timeline-body {
  font-size: 96%;
}

@media screen and (min-width: 768px) {
  .timeline {
    padding: 64px 0;
    display: flow-root;
  }
  .timeline::before {
    left: calc(50% - 2px);
    display: block;
  }
  .timeline li {
    position: relative;
    margin: 0;
  }
  .timeline .timeline-badge {
    position: relative;
    display: block;
  }
  .timeline .timeline-badge::before {
    content: "";
    position: absolute;
    display: block;
    width: 16px;
    height: 16px;
    background: radial-gradient(
      circle,
      var(--white) 0,
      var(--white) 6%,
      var(--primary) 100%
    );
    border-radius: 50%;
  }
  .timeline li:nth-child(odd) {
    float: left;
    width: 50%;
  }
  .timeline li .timeline-panel {
    /* transform: translateY(-62px); */
  }
  .timeline li:nth-child(odd) .timeline-panel {
    transform: translate3d(
      calc(var(--timelinePanelXoffset) * -1),
      var(--timelinePanelYoffset),
      0
    );
  }

  .timeline li:nth-child(even) .timeline-panel {
    transform: translate3d(
      var(--timelinePanelXoffset),
      var(--timelinePanelYoffset),
      0
    );
  }
  .timeline li:nth-child(odd) .timeline-badge {
    text-align: right;
    padding: 0 24px 0 0;
  }
  .timeline li:nth-child(odd) .timeline-badge::before {
    right: -8px;
  }
  .timeline li:nth-child(even) .timeline-badge {
    padding: 0 0 0 24px;
  }
  .timeline li:nth-child(even) .timeline-badge::before {
    left: -8px;
  }
  .timeline li:nth-child(odd) .timeline-panel {
    width: 70%;
  }
  .timeline li:nth-child(odd) .timeline-panel::before {
    content: "";
    position: absolute;
    right: -32px;
    top: 28px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 0 22px 32px;
    border-color: transparent transparent transparent #ffffff;
  }
  .timeline li:nth-child(even) .timeline-panel::before {
    content: "";
    position: absolute;
    left: -32px;
    top: 28px;
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 22px 32px 22px 0;
    border-color: transparent #ffffff transparent transparent;
  }
  .timeline li:nth-child(even) {
    width: 50%;
    left: 50%;
  }
  .timeline li:nth-child(even) .timeline-panel {
    width: 70%;
    /* transform: translateY(-30px); */
    float: right;
  }
  .timeline li::after {
    content: "";
    clear: both;
    display: table;
  }
}
