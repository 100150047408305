/* zilla-slab-300 */
@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 300;
  src: local(""), url("../fonts/zilla-slab-v6-latin-300.woff2") format("woff2"),
    url("../fonts/zilla-slab-v6-latin-300.woff") format("woff");
  font-display: swap;
}
/* zilla-slab-regular */
@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/zilla-slab-v6-latin-regular.woff2") format("woff2"),
    url("../fonts/zilla-slab-v6-latin-regular.woff") format("woff");
  font-display: swap;
}
/* zilla-slab-700 */
@font-face {
  font-family: "Zilla Slab";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/zilla-slab-v6-latin-700.woff2") format("woff2"),
    url("../fonts/zilla-slab-v6-latin-700.woff") format("woff");
  font-display: swap;
}

/* fira-sans-200 */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 200;
  src: local(""), url("../fonts/fira-sans-v11-latin-200.woff2") format("woff2"),
    url("../fonts/fira-sans-v11-latin-200.woff") format("woff");
  font-display: swap;
}
/* fira-sans-regular */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src: local(""),
    url("../fonts/fira-sans-v11-latin-regular.woff2") format("woff2"),
    url("../fonts/fira-sans-v11-latin-regular.woff") format("woff");
  font-display: swap;
}
/* fira-sans-italic */
@font-face {
  font-family: "Fira Sans";
  font-style: italic;
  font-weight: 400;
  src: local(""),
    url("../fonts/fira-sans-v11-latin-italic.woff2") format("woff2"),
    url("../fonts/fira-sans-v11-latin-italic.woff") format("woff");
  font-display: swap;
}
/* fira-sans-700 */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 700;
  src: local(""), url("../fonts/fira-sans-v11-latin-700.woff2") format("woff2"),
    url("../fonts/fira-sans-v11-latin-700.woff") format("woff");
  font-display: swap;
}
/* fira-sans-900 */
@font-face {
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 900;
  src: local(""), url("../fonts/fira-sans-v11-latin-900.woff2") format("woff2"),
    url("../fonts/fira-sans-v11-latin-900.woff") format("woff");
  font-display: swap;
}
